import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { DownloadOutlined } from "@ant-design/icons";
import MenuItem from "@mui/material/MenuItem";
import { TextField } from "@mui/material";
import Header from "./Header";
import { Typography, Radio, Col, Button, Space, Modal } from "antd";
import { PiPaperPlaneRight } from "react-icons/pi";
import { HiChevronDoubleLeft } from "react-icons/hi2";
import moment from "moment/moment";
import countries from "../json_datas/countries.json";
import { updateStudent } from "../../../redux/StudentRegd/StudentRegdSlice";
import {
  dashPageStatuseChange,
  setInternationalTravel,
} from "../../../redux/dashboard/PageRenderSlice";

const TravelData = () => {
  const { loginData } = useSelector((store) => store.Athentication);
  const { countryList, personalInfo } = useSelector(
    (store) => store.DashboardPage
  );
  const { currentFormStep, studentDetail } = useSelector(
    (store) => store.StudentRegd
  );

  const [passwordNo, setPasswordNo] = useState("");
  const [passwordValidMonth, setPasswordvalidMonth] = useState("");
  const [passwordValidDate, setPasswordvalidDate] = useState("");
  const [passwordValidYear, setPasswordvalidYear] = useState("");
  const [passwordValid, setPasswordvalid] = useState("");
  const [passwordIssue, setPasswordIssue] = useState(
    studentDetail.personalInformation.countryofresidence
  );
  const [issueUSVisa, setIssueUSVisa] = useState(false);
  const [visaRejection, setVisaRejection] = useState(false);
  const [countryTravelled, setCountryTravelled] = useState(false);
  const [travelDate1Year, setTravelDate1Year] = useState("");
  const [travelDate1Month, setTravelDate1Nonth] = useState("");
  const [travelDate1Day, setTravelDate1Day] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [donthavepassport, setdonthavepassport] = useState(true);
  // Error Message
  const [passwordNoError, setPasswordNoError] = useState("");
  const [passwordValidError, setPasswordValidError] = useState("");
  const [addCountry, setAddCountry] = useState(false);

  const [passwordValidMonthError, setPasswordvalidMonthError] = useState("");
  const [passwordValidDateError, setPasswordvalidDateError] = useState("");
  const [passwordValidYearError, setPasswordvalidYearError] = useState("");
  const [traveldataError, settraveldataError] = useState("");
  const [traveldata1Error, settraveldata1Error] = useState("");

  const [usVisaIssure_year, setusVisaIssure_year] = useState("");
  const [usVisaIssure_month, setusVisaIssure_month] = useState("");
  const [usVisaIssure_date, setusVisaIssure_date] = useState("");
  const [usVisaIssure_visatype, setusVisaIssure_visatype] = useState("");
  const [usVisaIssure_error, setusVisaIssure_error] = useState("");

  const [usVisaRegection_year, setusVisaRegection_year] = useState("");
  const [usVisaRegection_month, setusVisaRegection_month] = useState("");
  const [usVisaRegection_date, setusVisaRegection_date] = useState("");
  const [usVisaRegection_visatype, setusVisaRegection_visatype] = useState("");
  const [usVisaRegection_error, setusVisaRegection_error] = useState("");

  const [country_travelled_year, setcountry_travelled_year] = useState("");
  const [country_travelled_month, setcountry_travelled_month] = useState("");
  const [country_travelled_date, setcountry_travelled_date] = useState("");
  const [country_travelled_1, setcountry_travelled_1] = useState("");
  const [country_travelled_error, setcountry_travelled_error] = useState("");

  const [showDateByMounth, setshowDateByMounth] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (studentDetail && studentDetail.currentFormStep > 2) {
      setPasswordNo(studentDetail.travelData.passwordNo);

      // setPasswordvalid(studentDetail.travelData.passwordValid);
      setPasswordIssue(studentDetail.travelData.passwordIssue);

      // setIssueUSVisa(studentDetail.travelData.issueUSVisa);
      // setIssuanceDate(studentDetail.travelData.issuanceDate);
      // setVisaType(studentDetail.travelData.visaType);

      // setVisaRejection(studentDetail.travelData.visaRejection);
      // setVisaRejDate(studentDetail.travelData.visaRejDate);
      // setVisaRejType(studentDetail.travelData.visaRejType);

      // setCountryTravelled(studentDetail.travelData.countryTravelled);
      // setCountryName(studentDetail.travelData.countryName);
      // setCountryName1(studentDetail.travelData.countryName1);
      // setTravelDate(studentDetail.travelData.travelDate);
      // setTravelDate1(studentDetail.travelData.travelDate1);

      if (studentDetail.travelData.passwordValid !== "") {
        const text = studentDetail.travelData.passwordValid;
        const firstSplit = text.split("-");
        if (firstSplit.length === 3) {
          const textone = firstSplit[0];
          const texttwo = firstSplit[1];
          const textThree = firstSplit[2];
          setPasswordvalidYear(textone);
          setPasswordvalidMonth(texttwo);
          setPasswordvalidDate(textThree);
        }
      }
      if (studentDetail.travelData.countryTravelled === true) {
        const text = studentDetail.travelData.travelDate;
        const firstSplit = text.split("-");
        if (firstSplit.length === 3) {
          const textone = firstSplit[0];
          const texttwo = firstSplit[1];
          const textThree = firstSplit[2];
          setcountry_travelled_year(textone);
          setcountry_travelled_month(texttwo);
          setcountry_travelled_date(textThree);
          setcountry_travelled_1(studentDetail.travelData.countryName);
        }
        const text1 = studentDetail.travelData.travelDate1;
        const firstSplit1 = text1.split("-");
        if (firstSplit1.length === 3) {
          const textone = firstSplit1[0];
          const texttwo = firstSplit1[1];
          const textThree = firstSplit1[2];
          setTravelDate1Year(textone);
          setTravelDate1Nonth(texttwo);
          setTravelDate1Day(textThree);

          if (text1 !== "") {
            setAddCountry(true);
          }
        }
      }
    }
  }, [studentDetail]);

  const selectDate = [];

  for (let i = 1; i <= 31; i++) {
    selectDate.push({
      id: i,
      name: i.toString(),
    });
  }

  const selectMonth = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" },
  ];

  const currentYear = new Date().getFullYear(); // Get the current year
  const SelectYear = [];

  for (let i = 0; i < 15; i++) {
    SelectYear.push({
      id: i + 1,
      name: (currentYear + i).toString(),
    });
  }
  const SelectPastYear = [];

  for (let j = 0; j < 10; j++) {
    SelectPastYear.push({
      id: j - 1,
      name: (currentYear - j).toString(),
    });
  }
  const monthsanddates = [
    {
      month: "January",
      dates: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
      ],
    },
    {
      month: "February",
      dates: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28,
      ],
    },
    {
      month: "March",
      dates: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
      ],
    },
    {
      month: "April",
      dates: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
      ],
    },
    {
      month: "May",
      dates: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
      ],
    },
    {
      month: "June",
      dates: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
      ],
    },
    {
      month: "July",
      dates: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
      ],
    },
    {
      month: "August",
      dates: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
      ],
    },
    {
      month: "September",
      dates: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
      ],
    },
    {
      month: "October",
      dates: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
      ],
    },
    {
      month: "November",
      dates: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
      ],
    },
    {
      month: "December",
      dates: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
      ],
    },
  ];

  const selectDates = [];

  for (let i = 1; i <= 31; i++) {
    selectDates.push({
      id: i,
      name: i.toString(),
    });
  }

  useEffect(() => {
    if (passwordValidMonth) {
      const filterdata = monthsanddates.find(
        (data) => data.month === passwordValidMonth
      );
      setshowDateByMounth([...filterdata.dates]);
    }
  }, [passwordValidMonth]);

  const selectRejectedVisaType = [
    {
      id: 1,
      name: "H",
    },
    {
      id: 2,
      name: "B",
    },
    {
      id: 3,
      name: "F",
    },
    {
      id: 4,
      name: "L",
    },
    {
      id: 5,
      name: "Other",
    },
  ];

  const selectVisaType = [
    {
      id: 1,
      name: "H",
    },
    {
      id: 2,
      name: "B",
    },
    {
      id: 3,
      name: "F",
    },
    {
      id: 4,
      name: "L",
    },
    {
      id: 5,
      name: "Other",
    },
  ];

  const SelectDateChange = (e) => {
    const value = e.target.value;
    console.log(value, "value");
    setPasswordvalidDate(value);
    let formate = `${value}/${passwordValidMonth}/${passwordValidYear}`;

    let day = new Date(formate);
    day = day.toDateString();
    let currentDatae = new Date();
    currentDatae.toDateString();

    if (moment(day).format("YYYY") === moment(currentDatae).format("YYYY")) {
      if (moment(day).format("M") >= moment(currentDatae).format("M")) {
        Modal.warning({
          title: "This is a warning message",
          content:
            "Passports must always display a validity date in the future.",
          onOk() {
            // navigate("/");
          },
          okText: "Got It",
        });
        setPasswordvalidYear("");
        setPasswordvalidMonth("");
        setPasswordvalidDate("");
      } else {
      }
    } else {
    }
  };

  const InternationaltravelClick = async () => {
    if (studentDetail.currentFormStep > 2) {
      dispatch(dashPageStatuseChange(3));
    } else {
      if (!donthavepassport) {
        dispatch(dashPageStatuseChange(3));
      } else {
        try {
          if (passwordNo === "") {
            setPasswordNoError("Required");
          } else {
            setPasswordNoError("");
          }
          if (
            passwordValidMonth === "" ||
            passwordValidDate === "" ||
            passwordValidYear === ""
          ) {
            setPasswordValidError("Required");
          } else {
            setPasswordValidError("");
          }
          let passwordIssuedDate = "";
          let passwordIssuedVisa = "";
          if (issueUSVisa === true) {
            if (
              usVisaIssure_year === "" ||
              usVisaIssure_month === "" ||
              usVisaIssure_date === "" ||
              usVisaIssure_visatype === ""
            ) {
              setusVisaIssure_error("Required");
            } else {
              passwordIssuedDate = `${usVisaIssure_year}-${usVisaIssure_month}-${usVisaIssure_date}`;
              passwordIssuedVisa = usVisaIssure_visatype;
              setusVisaIssure_error("");
            }
          } else {
            passwordIssuedDate = " ";
            passwordIssuedVisa = " ";
          }
          let rejectionPassportDate = "";
          let rejectedPasswortType = "";
          if (visaRejection === true) {
            if (
              usVisaRegection_year === "" ||
              usVisaRegection_month === "" ||
              usVisaRegection_date === "" ||
              usVisaRegection_visatype === ""
            ) {
              setusVisaRegection_error("Required");
            } else {
              rejectionPassportDate = `${usVisaRegection_year}-${usVisaRegection_month}-${usVisaRegection_date}`;
              rejectedPasswortType = usVisaRegection_visatype;
              setusVisaRegection_error("");
            }
          } else {
            rejectionPassportDate = " ";
            rejectedPasswortType = " ";
          }
          let travelledCountry1 = "";
          let traveledDate1 = "";
          if (countryTravelled === true) {
            if (
              country_travelled_year === "" ||
              country_travelled_month === "" ||
              country_travelled_date === "" ||
              country_travelled_1 === ""
            ) {
              setcountry_travelled_error("Required");
            } else {
              traveledDate1 = `${country_travelled_year}-${country_travelled_month}-${country_travelled_date}`;
              travelledCountry1 = country_travelled_1;
              setcountry_travelled_error("");
            }
          } else {
            traveledDate1 = " ";
            travelledCountry1 = " ";
          }

          if (
            passwordNo !== "" &&
            passwordValidMonth !== "" &&
            passwordValidDate !== "" &&
            passwordValidYear !== "" &&
            passwordIssuedDate !== "" &&
            passwordIssuedVisa !== "" &&
            rejectionPassportDate !== "" &&
            rejectedPasswortType !== "" &&
            travelledCountry1 !== "" &&
            traveledDate1 !== "" &&
            passwordIssue !== ""
          ) {
            let dateVal = "";
            dateVal = `${passwordValidYear}-${passwordValidMonth}-${passwordValidDate}`;

            const formData = {
              _id: loginData._id,
              travelData: {
                passwordNo: passwordNo,
                passwordValid: dateVal,
                passwordIssue: passwordIssue,

                issueUSVisa: issueUSVisa,
                issuanceDate: passwordIssuedDate.trim(),
                visaType: passwordIssuedVisa.trim(),

                visaRejection: visaRejection,
                visaRejDate: rejectionPassportDate.trim(),
                visaRejType: rejectedPasswortType.trim(),
                countryTravelled: countryTravelled,
                countryName: travelledCountry1.trim(),
                travelDate: traveledDate1.trim(),
                // countryName1: travdata1Country,
                // travelDate1: travdata1,
              },
              currentFormStep: 3,
              travelDataStatus: true,
            };
            const student = await dispatch(updateStudent(formData));
            if (student.payload.success) {
              dispatch(setInternationalTravel(formData));
              dispatch(dashPageStatuseChange(3));
            }
          } else {
          }
        } catch (error) {}
      }
    }
  };

  return (
    <>
      <div className="main_container">
        <div className="container_box">
          <Header
            title="Travel Data"
            description="International travel requires a passport with a minimum of 6 months' validity."
          />
          <div className="inputs_container">
            <div className="radio_div_container">
              <span className="radio_title">Do you have a passport?</span>

              <Radio.Group defaultValue={true}>
                <Radio
                  value={true}
                  checked={donthavepassport == true}
                  onClick={() => {
                    setdonthavepassport(true);
                  }}
                >
                  Yes
                </Radio>
                <Radio
                  value={false}
                  checked={donthavepassport == false}
                  onClick={() => {
                    setdonthavepassport(false);
                  }}
                >
                  No
                </Radio>
              </Radio.Group>
            </div>
            {donthavepassport && (
              <>
                <div className="dn_input_box">
                  <TextField
                    label="Passport Number"
                    type="text"
                    id="text"
                    className="input_style"
                    error={passwordNoError !== "" ? true : false}
                    helperText={passwordNoError}
                    onChange={(e) => {
                      setPasswordNo(e.target.value);
                    }}
                    value={passwordNo}
                    autocomplete="off"
                    size="small"
                  />
                </div>
                <div className="dn_input_box">
                  <div className="drop_small_container">
                    <TextField
                      id="select"
                      label="Valid till year"
                      select
                      className="dropd_small_input_style"
                      error={passwordValidError !== "" ? true : false}
                      helperText={passwordValidError}
                      onChange={(e) => setPasswordvalidYear(e.target.value)}
                      value={passwordValidYear}
                      size="small"
                    >
                      {SelectYear.map((year, index) => (
                        <MenuItem key={index} value={year.name}>
                          {year.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      id="select"
                      label="Month"
                      select
                      className="dropd_small_input_style"
                      error={passwordValidError !== "" ? true : false}
                      helperText={passwordValidError}
                      onChange={(e) => setPasswordvalidMonth(e.target.value)}
                      value={passwordValidMonth}
                      size="small"
                    >
                      {monthsanddates.map((year, index) => (
                        <MenuItem key={index} value={year.month}>
                          {year.month}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      id="select"
                      label="Date"
                      select
                      className="dropd_small_input_style"
                      error={passwordValidError !== "" ? true : false}
                      helperText={passwordValidError}
                      onChange={(e) => SelectDateChange(e)}
                      value={passwordValidDate}
                      size="small"
                    >
                      {showDateByMounth.map((date, index) => (
                        <MenuItem key={index} value={date}>
                          {date}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>
                <div className="dn_input_box">
                  <TextField
                    id="select"
                    label="Issued by"
                    select
                    className="input_style"
                    // error={passwordIssueError !== "" ? true : false}
                    // helperText={passwordIssueError}
                    onChange={(e) => {
                      setPasswordIssue(e.target.value);
                    }}
                    value={
                      passwordIssue === ""
                        ? studentDetail.personalInformation.countryofresidence
                        : passwordIssue
                    }
                    size="small"
                  >
                    {countries.map((country, index) => (
                      <MenuItem key={index} value={country.name}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className="dn_input_box">
                  <div className="input_style"></div>
                </div>
                <div className="radio_div_container">
                  <span className="radio_title">
                    Have you ever been issued a US Visa?
                  </span>

                  <Radio.Group defaultValue={false}>
                    <Radio
                      value={true}
                      checked={issueUSVisa == true}
                      onClick={() => {
                        setIssueUSVisa(true);
                      }}
                    >
                      Yes
                    </Radio>
                    <Radio
                      value={false}
                      checked={issueUSVisa == false}
                      onClick={() => {
                        setIssueUSVisa(false);
                      }}
                    >
                      No
                    </Radio>
                  </Radio.Group>
                </div>
                {issueUSVisa === true && (
                  <>
                    <div className="dn_input_box">
                      <div className="drop_small_container">
                        <TextField
                          id="select"
                          label="Year"
                          select
                          className="dropd_small_input_style"
                          error={usVisaIssure_error !== "" ? true : false}
                          helperText={usVisaIssure_error}
                          onChange={(e) => setusVisaIssure_year(e.target.value)}
                          value={usVisaIssure_year}
                          size="small"
                        >
                          {SelectPastYear.map((year, index) => (
                            <MenuItem key={index} value={year.name}>
                              {year.name}
                            </MenuItem>
                          ))}
                        </TextField>
                        <TextField
                          id="select"
                          label="Month"
                          select
                          className="dropd_small_input_style"
                          error={usVisaIssure_error !== "" ? true : false}
                          helperText={usVisaIssure_error}
                          onChange={(e) =>
                            setusVisaIssure_month(e.target.value)
                          }
                          value={usVisaIssure_month}
                          size="small"
                        >
                          {monthsanddates.map((year, index) => (
                            <MenuItem key={index} value={year.month}>
                              {year.month}
                            </MenuItem>
                          ))}
                        </TextField>
                        <TextField
                          id="select"
                          label="Date"
                          select
                          className="dropd_small_input_style"
                          error={usVisaIssure_error !== "" ? true : false}
                          helperText={usVisaIssure_error}
                          onChange={(e) => setusVisaIssure_date(e.target.value)}
                          value={usVisaIssure_date}
                          size="small"
                        >
                          {selectDates.map((date, index) => (
                            <MenuItem key={index} value={date.name}>
                              {date.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </div>
                    <div className="dn_input_box">
                      <TextField
                        id="select"
                        label="Visa Type"
                        select
                        className="input_style"
                        error={usVisaIssure_error !== "" ? true : false}
                        helperText={usVisaIssure_error}
                        onChange={(e) =>
                          setusVisaIssure_visatype(e.target.value)
                        }
                        value={usVisaIssure_visatype}
                        size="small"
                      >
                        {selectVisaType.map((date, index) => (
                          <MenuItem key={index} value={date.name}>
                            {date.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  </>
                )}
                <div className="radio_div_container">
                  <span className="radio_title">
                    Have your US visa application ever been rejected?
                  </span>

                  <Radio.Group defaultValue={false}>
                    <Radio
                      value={true}
                      checked={visaRejection == true}
                      onClick={() => {
                        setVisaRejection(true);
                      }}
                    >
                      Yes
                    </Radio>
                    <Radio
                      value={false}
                      checked={visaRejection == false}
                      onClick={() => {
                        setVisaRejection(false);
                      }}
                    >
                      No
                    </Radio>
                  </Radio.Group>
                </div>
                {visaRejection === true && (
                  <>
                    <div className="dn_input_box">
                      <div className="drop_small_container">
                        <TextField
                          id="select"
                          label="Year"
                          select
                          className="dropd_small_input_style"
                          error={usVisaRegection_error !== "" ? true : false}
                          helperText={usVisaRegection_error}
                          onChange={(e) =>
                            setusVisaRegection_year(e.target.value)
                          }
                          value={usVisaRegection_year}
                          size="small"
                        >
                          {SelectPastYear.map((year, index) => (
                            <MenuItem key={index} value={year.name}>
                              {year.name}
                            </MenuItem>
                          ))}
                        </TextField>
                        <TextField
                          id="select"
                          label="Month"
                          select
                          className="dropd_small_input_style"
                          error={usVisaRegection_error !== "" ? true : false}
                          helperText={usVisaRegection_error}
                          onChange={(e) =>
                            setusVisaRegection_month(e.target.value)
                          }
                          value={usVisaRegection_month}
                          size="small"
                        >
                          {monthsanddates.map((year, index) => (
                            <MenuItem key={index} value={year.month}>
                              {year.month}
                            </MenuItem>
                          ))}
                        </TextField>
                        <TextField
                          id="select"
                          label="Date"
                          select
                          className="dropd_small_input_style"
                          error={usVisaRegection_error !== "" ? true : false}
                          helperText={usVisaRegection_error}
                          onChange={(e) =>
                            setusVisaRegection_date(e.target.value)
                          }
                          value={usVisaRegection_date}
                          size="small"
                        >
                          {selectDates.map((date, index) => (
                            <MenuItem key={index} value={date.name}>
                              {date.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </div>
                    <div className="dn_input_box">
                      <TextField
                        id="select"
                        label="Visa Type"
                        select
                        className="input_style"
                        error={usVisaRegection_error !== "" ? true : false}
                        helperText={usVisaRegection_error}
                        onChange={(e) =>
                          setusVisaRegection_visatype(e.target.value)
                        }
                        value={usVisaRegection_visatype}
                        size="small"
                      >
                        {selectVisaType.map((date, index) => (
                          <MenuItem key={index} value={date.name}>
                            {date.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  </>
                )}
                <div className="radio_div_container">
                  <span className="radio_title">
                    Have you ever traveled out of your country?
                  </span>

                  <Radio.Group defaultValue={false}>
                    <Radio
                      value={true}
                      checked={countryTravelled == true}
                      onClick={() => {
                        setCountryTravelled(true);
                      }}
                    >
                      Yes
                    </Radio>
                    <Radio
                      value={false}
                      checked={countryTravelled == false}
                      onClick={() => {
                        setCountryTravelled(false);
                      }}
                    >
                      No
                    </Radio>
                  </Radio.Group>
                </div>
                {countryTravelled === true && (
                  <>
                    <div className="dn_input_box">
                      <TextField
                        id="select"
                        label="Country"
                        select
                        className="input_style"
                        error={country_travelled_error !== "" ? true : false}
                        helperText={country_travelled_error}
                        onChange={(e) => setcountry_travelled_1(e.target.value)}
                        value={country_travelled_1}
                        size="small"
                      >
                        {countries.map((country, index) => (
                          <MenuItem key={index} value={country.name}>
                            {country.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                    <div className="dn_input_box">
                      <div className="drop_small_container">
                        <TextField
                          id="select"
                          label="Year"
                          select
                          className="dropd_small_input_style"
                          error={country_travelled_error !== "" ? true : false}
                          helperText={country_travelled_error}
                          onChange={(e) =>
                            setcountry_travelled_year(e.target.value)
                          }
                          value={country_travelled_year}
                          size="small"
                        >
                          {SelectPastYear.map((year, index) => (
                            <MenuItem key={index} value={year.name}>
                              {year.name}
                            </MenuItem>
                          ))}
                        </TextField>
                        <TextField
                          id="select"
                          label="Month"
                          select
                          className="dropd_small_input_style"
                          error={country_travelled_error !== "" ? true : false}
                          helperText={country_travelled_error}
                          onChange={(e) =>
                            setcountry_travelled_month(e.target.value)
                          }
                          value={country_travelled_month}
                          size="small"
                        >
                          {monthsanddates.map((year, index) => (
                            <MenuItem key={index} value={year.month}>
                              {year.month}
                            </MenuItem>
                          ))}
                        </TextField>
                        <TextField
                          id="select"
                          label="Date"
                          select
                          className="dropd_small_input_style"
                          error={country_travelled_error !== "" ? true : false}
                          helperText={country_travelled_error}
                          onChange={(e) =>
                            setcountry_travelled_date(e.target.value)
                          }
                          value={country_travelled_date}
                          size="small"
                        >
                          {selectDates.map((date, index) => (
                            <MenuItem key={index} value={date.name}>
                              {date.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
            <div className="button_container_distance">
              <Button
                type="primary"
                loading={loading}
                iconPosition="start"
                icon={<HiChevronDoubleLeft />}
                size="large"
                onClick={() => dispatch(dashPageStatuseChange(1))}
                 className="back_btn_btn"
              >
                Back
              </Button>
              <Button
                type="primary"
                loading={loading}
                iconPosition="end"
                icon={<PiPaperPlaneRight />}
                size="large"
                onClick={() => InternationaltravelClick()}
              >
                Save & Next
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TravelData;
