import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const Footer = () => {
  let navigate=useNavigate()
  const [email,setEmail]=useState("")
  const handleclick=(e)=>{
    e.preventDefault()
    if(email===""){
    navigate('/');
    }
    else{
      navigate('/sign-up')
    }
    setEmail("")


  }
  return (
    <>
      <footer className="footer-area">
        <div className="footer-top pt-20 pb-55">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-xl-3 col-lg-7 col-md-7 col-sm-12">
                <div className="footer-widget mb-40">
                  <div className="footer-logo">
                    <Link to="/">
                      <img src="/assets/img/logo/f1visalogo8.svg" alt />
                    </Link>
                  </div>
                  <p className="footer-widget-text mb-35">
                    Through a combination of lectures, readings, discussions,
                    students will gain solid foundation in educational.
                  </p>
                  <div className="footer-social">
                    <ul>
                      <li>
                        <Link>
                          <i className="fa-brands fa-twitter" />
                        </Link>
                      </li>
                      <li>
                        <Link>
                          <i className="fa-brands fa-facebook-f" />
                        </Link>
                      </li>
                      <li>
                        <Link>
                          <i className="fa-brands fa-linkedin-in" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-5 col-md-5 col-sm-6 d-flex justify-content-xl-center">
                <div className="footer-widget mb-40">
                  <h5 className="footer-widget-title">Useful Link</h5>
                  <div className="footer-widget-list">
                    <ul>
                      {/* <li>
                        <Link to="/">How it works</Link>
                      </li> */}
                      <li>
                        <Link to="/universities">University</Link>
                      </li>
                      <li>
                        <Link to="/pricing">Pricing</Link>
                      </li>
                      <li>
                        <Link to="/faq">FAQ</Link>
                      </li>
                      <li>
                        <Link to="/blogs">Blog</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-5 col-md-5 col-sm-6 d-flex justify-content-xl-center order-md-4 order-xl-3">
                <div className="footer-widget mb-40">
                  <h5 className="footer-widget-title">SUPPORT</h5>
                  <div className="footer-widget-list">
                    <ul>
                      <li>
                        <Link to="/contact-us">Contact us</Link>
                      </li>
                      <li>
                        <Link to="/about-us">About us</Link>
                      </li>
                      <li>
                        <Link to="/careers">Careers</Link>
                      </li>
                      {/* <li>
                        <Link>Privacy Policy</Link>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-7 col-md-7 col-sm-12 order-md-3 order-xl-4">
                <div className="footer-widget ml-80 mb-40">
                  <h5 className="footer-widget-title">Newsletter</h5>
                  <p className="footer-widget-text mb-20 newsletter-text">
                    Sign up for our newsletter and get 34% <br /> off your next
                    course.
                  </p>
                  <form onSubmit={handleclick}>
                    <div className="footer-subscribe-form" >
                      <input type="email" placeholder="Enter Your Email*" value={email} onChange={(e)=>
                        setEmail(e.target.value)
                      }/>
                      <button
                        style={{
                          background: "#395BDF",
                        }}
                        type="submit"
                        >
                        Subscribe
                      </button>
                    </div>
                    <div className="footer-subscribe-condition">
                      <label className="condition_label">
                        I agree to the terms of use and privacy policy.
                        <input type="checkbox" />
                        <span className="check_mark" />
                      </label>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-area">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="copyright-text">
                  <p>Copyright © 2024 All Rights Reserved by F1VISA.online</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
