import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import { TextField, Autocomplete } from "@mui/material";
import { styled } from "@mui/system";
import Divider from "@mui/material/Divider";
import { Typography, Radio, Col, Button, Space, Modal } from "antd";
import { PiPaperPlaneRight } from "react-icons/pi";
import countries from "../json_datas/countries.json";
import cities from "../json_datas/countries+cities.json";
import programs from "../json_datas/UnderGraduationdata.json";
import Header from "./Header";
import { FaInfoCircle } from "react-icons/fa";
import { HiChevronDoubleLeft } from "react-icons/hi2";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { updateStudent } from "../../../redux/StudentRegd/StudentRegdSlice";
import {
  dashPageStatuseChange,
  setEducationalEvaluation,
} from "../../../redux/dashboard/PageRenderSlice";

const Education = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loginData } = useSelector((store) => store.Athentication);
  const { studentDetail } = useSelector((store) => store.StudentRegd);
  const { major_subjects } = useSelector((store) => store.testManagement);
  const [buttonPress, setbuttonPress] = useState(false);
  const [buttonPress1, setbuttonPress1] = useState(false);

  const [Educational, setEducational] = useState(false);
  const [agencyName, setAgencyName] = useState("");
  const [Reference, setReference] = useState("");

  const [diplomaList, setdiplomaList] = useState([]);

  const [showCityByCountry, setshowCityByCountry] = useState([]);

  const [GraduationOption, setGraduationOption] = useState([
    {
      country: "",
      institute: "",
      city: "",
      degree: "",
      graduationMonth: "",
      graduationYear: "",
      scoringType: "Grade",
      Scoring: "",
      gpa: "",
      // primerySubject: "Animal Sciences",
      primerySubject: "",
      primerySubjectCIPCODE: "",
      AdditionalSubject1: "",
      AdditionalSubject1CIPCODE: "",
      AdditionalSubject2: "",
      AdditionalSubject2CIPCODE: "",
    },
  ]);

  const [info_model, setinfo_model] = useState(false);

  const [GraduationOptionIndex, setGraduationOptionIndex] = useState("");
  const [GraduationOptionBtnPress, setGraduationOptionBtnPress] =
    useState(false);
  const [GraduationOptioncountry, setGraduationOptioncountry] = useState("");
  const [GraduationOptioninstitute, setGraduationOptioninstitute] =
    useState("");
  const [GraduationOptioncity, setGraduationOptioncity] = useState("");
  const [GraduationOptiondegree, setGraduationOptiondegree] = useState("");
  const [GraduationOptiongraduationMonth, setGraduationOptiongraduationMonth] =
    useState("");
  const [GraduationOptiongraduationYear, setGraduationOptiongraduationYear] =
    useState("");
  const [GraduationOptionMajorSubject, setGraduationOptionMajorSubject] =
    useState("");
  const [GraduationOptionscoringType, setGraduationOptionscoringType] =
    useState("");
  const [GraduationOptiongpa, setGraduationOptiongpa] = useState("");

  const [agencyNameError, setAgencyNameError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [AdditionalSubject1, setAdditionalSubject1] = useState([]);
  const [AdditionalSubject2, setAdditionalSubject2] = useState([]);

  useEffect(() => {
    if (studentDetail.currentFormStep >= 5) {
      setEducational(studentDetail.education.Educational);
      setAgencyName(studentDetail.education.agencyName);
      setReference(studentDetail.education.Reference);
      setGraduationOption([...studentDetail.education.GraduationOption]);
    }
  }, [studentDetail]);

  useEffect(() => {
    if (buttonPress === true) {
      if (Educational === true) {
        if (agencyName === "") {
          setAgencyNameError("Required");
        } else {
          setAgencyNameError("");
        }
        if (Reference === "") {
          setCountryError("Required");
        } else {
          setCountryError("");
        }
      } else {
        setAgencyNameError("");
        setCountryError("");
      }
    }
    if (GraduationOptionBtnPress === true) {
      const lastIndex = GraduationOption.length - 1;
      if (GraduationOption[lastIndex].country === "") {
        setGraduationOptionIndex(lastIndex);
        setGraduationOptioncountry("Required");
        setGraduationOptionBtnPress(true);
      } else {
        setGraduationOptioncountry("");
      }
      if (GraduationOption[lastIndex].institute === "") {
        setGraduationOptionIndex(lastIndex);
        setGraduationOptioninstitute("Required");
        setGraduationOptionBtnPress(true);
      } else {
        setGraduationOptioninstitute("");
      }
      if (GraduationOption[lastIndex].city === "") {
        setGraduationOptionIndex(lastIndex);
        setGraduationOptioncity("Required");
        setGraduationOptionBtnPress(true);
      } else {
        setGraduationOptioncity("");
      }
      if (GraduationOption[lastIndex].degree === "") {
        setGraduationOptionIndex(lastIndex);
        setGraduationOptiondegree("Required");
        setGraduationOptionBtnPress(true);
      } else {
        setGraduationOptiondegree("");
      }
      if (GraduationOption[lastIndex].graduationMonth === "") {
        setGraduationOptionIndex(lastIndex);
        setGraduationOptiongraduationMonth("Required");
        setGraduationOptionBtnPress(true);
      } else {
        setGraduationOptiongraduationMonth("");
      }
      if (GraduationOption[lastIndex].graduationYear === "") {
        setGraduationOptionIndex(lastIndex);
        setGraduationOptiongraduationYear("Required");
        setGraduationOptionBtnPress(true);
      } else {
        setGraduationOptiongraduationYear("");
      }
      if (GraduationOption[lastIndex].scoringType === "") {
        setGraduationOptionIndex(lastIndex);
        setGraduationOptionscoringType("Required");
        setGraduationOptionBtnPress(true);
      } else {
        setGraduationOptionscoringType("");
      }
      if (GraduationOption[lastIndex].gpa === "") {
        setGraduationOptionIndex(lastIndex);
        setGraduationOptiongpa("Required");
        setGraduationOptionBtnPress(true);
      } else {
        setGraduationOptiongpa("");
      }
      if (GraduationOption[lastIndex].primerySubject === "") {
        setGraduationOptionIndex(lastIndex);
        setGraduationOptionMajorSubject("Required");
        setGraduationOptionBtnPress(true);
      } else {
        setGraduationOptionMajorSubject("");
      }
    }
  }, [
    buttonPress,
    Educational,
    agencyName,
    Reference,
    GraduationOptionBtnPress,
    GraduationOption,
  ]);

  const diplomaListArray = [
    {
      id: 1,
      name: "Post Secondary",
      educationLevel: "Undergraduate",
    },
    {
      id: 2,
      name: "Secondary Education/GED",
      educationLevel: "Undergraduate",
    },
    {
      id: 3,
      name: "Bachelors",
      educationLevel: "Undergraduate",
    },
    {
      id: 4,
      name: "Bachelors",
      educationLevel: "Graduate",
    },
    {
      id: 5,
      name: "Masters",
      educationLevel: "Graduate",
    },
    {
      id: 6,
      name: "Bachelors",
      educationLevel: "Doctoral",
    },
    {
      id: 7,
      name: "Masters",
      educationLevel: "Doctoral",
    },
  ];

  useEffect(() => {
    const filterGrad = diplomaListArray.filter(
      (data) => data.educationLevel === studentDetail.degreeName
    );
    setdiplomaList(filterGrad);
  }, [studentDetail]);

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const nextYear = currentYear + 1;

  const SelectYear = [];

  for (let i = 0; i < 50; i++) {
    SelectYear.push({
      id: i + 1,
      name: (nextYear - i).toString(),
    });
  }
  const selectMonth = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" },
  ];

  const selectGPAGrade = [
    {
      id: 1,
      name: "A+",
      type: "Grade",
      cgp: "4",
    },
    {
      id: 2,
      name: "A",
      type: "Grade",
      cgp: "3.7",
    },
    {
      id: 3,
      name: "B+",
      type: "Grade",
      cgp: "3.3",
    },
    {
      id: 4,
      name: "B",
      type: "Grade",
      cgp: "3",
    },
    {
      id: 5,
      name: "B-",
      type: "Grade",
      cgp: "2.7",
    },
    {
      id: 6,
      name: "C+",
      type: "Grade",
      cgp: "2.3",
    },
    {
      id: 7,
      name: "C",
      type: "Grade",
      cgp: "2",
    },
    {
      id: 8,
      name: "C-",
      type: "Grade",
      cgp: "1.7",
    },
    {
      id: 9,
      name: "D",
      type: "Grade",
      cgp: "1.3",
    },
    {
      id: 10,
      name: "D-",
      type: "Grade",
      cgp: "1",
    },
    {
      id: 11,
      name: "F",
      type: "Grade",
      cgp: "0",
    },
  ];
  const selectGPA = [
    {
      id: 1,
      name: "4",
      type: "Grade",
      cgp: "4",
    },
    {
      id: 2,
      name: "3.7",
      type: "Grade",
      cgp: "3.7",
    },
    {
      id: 3,
      name: "3.3",
      type: "Grade",
      cgp: "3.3",
    },
    {
      id: 4,
      name: "3",
      type: "Grade",
      cgp: "3",
    },
    {
      id: 5,
      name: "2.7",
      type: "Grade",
      cgp: "2.7",
    },
    {
      id: 6,
      name: "2.3",
      type: "Grade",
      cgp: "2.3",
    },
    {
      id: 7,
      name: "2",
      type: "Grade",
      cgp: "2",
    },
    {
      id: 8,
      name: "1.7",
      type: "Grade",
      cgp: "1.7",
    },
    {
      id: 9,
      name: "1.3",
      type: "Grade",
      cgp: "1.3",
    },
    {
      id: 10,
      name: "1",
      type: "Grade",
      cgp: "1",
    },
    {
      id: 11,
      name: "0",
      type: "Grade",
      cgp: "0",
    },
  ];
  const selectGPACgp = [
    {
      id: 12,
      name: "> 8.5",
      type: "CGPA",
      cgp: "4",
    },
    {
      id: 13,
      name: "8.0 - 8.4",
      type: "CGPA",
      cgp: "3.7",
    },
    {
      id: 14,
      name: "7.5 - 7.9+",
      type: "CGPA",
      cgp: "3.3",
    },
    {
      id: 15,
      name: "7.0 - 7.4",
      type: "CGPA",
      cgp: "3",
    },
    {
      id: 16,
      name: "6.5 - 6.9",
      type: "CGPA",
      cgp: "2.7",
    },
    {
      id: 17,
      name: "6.0 - 6.4",
      type: "CGPA",
      cgp: "2.3",
    },
    {
      id: 18,
      name: "5.5 - 5.9",
      type: "CGPA",
      cgp: "2",
    },
    {
      id: 19,
      name: "5.0 - 5.4",
      type: "CGPA",
      cgp: "1.7",
    },
    {
      id: 20,
      name: "4.5 - 4.9",
      type: "CGPA",
      cgp: "1.3",
    },
    {
      id: 21,
      name: "4.0 - 4.4",
      type: "CGPA",
      cgp: "1",
    },
    {
      id: 22,
      name: "0 - 3.9",
      type: "CGPA",
      cgp: "0",
    },
  ];
  const selectGPAPercentage = [
    {
      id: 23,
      name: "85  -100",
      type: "Percentage",
      cgp: "4",
    },
    {
      id: 24,
      name: "80 - 84",
      type: "Percentage",
      cgp: "3.7",
    },
    {
      id: 25,
      name: "75 - 7.9",
      type: "Percentage",
      cgp: "3.3",
    },
    {
      id: 26,
      name: "70 - 74",
      type: "Percentage",
      cgp: "3",
    },
    {
      id: 27,
      name: "65 - 69",
      type: "Percentage",
      cgp: "2.7",
    },
    {
      id: 28,
      name: "60 - 64",
      type: "Percentage",
      cgp: "2.3",
    },
    {
      id: 29,
      name: "55 - 59",
      type: "Percentage",
      cgp: "2",
    },
    {
      id: 30,
      name: "50 - 54",
      type: "Percentage",
      cgp: "1.7",
    },
    {
      id: 31,
      name: "45 - 49",
      type: "Percentage",
      cgp: "1.3",
    },
    {
      id: 32,
      name: "40 - 44",
      type: "Percentage",
      cgp: "1",
    },
    {
      id: 33,
      name: "0 - 39",
      type: "Percentage",
      cgp: "0",
    },
  ];

  const handleCheckGraduation = (index, e, selected, scoringselected) => {
    let temp = [...GraduationOption];
    if (
      selected !== "scoringType" ||
      selected !== "gpa" ||
      selected !== "country" ||
      selected !== "primerySubject" ||
      selected !== "AdditionalSubject1" ||
      selected !== "AdditionalSubject2"
    ) {
      temp[index][selected] = e?.target?.value;
    }

    if (selected === "scoringType") {
      temp[index][selected] = scoringselected;
    }
    if (selected === "gpa") {
      temp[index][selected] = e.target.value;
    }

    if (selected === "country") {
      const filterCity = cities.find(
        (data) => data.name.toLowerCase() === e.name.toLowerCase()
      );
      setshowCityByCountry([...filterCity.cities]);
      temp[index][selected] = e?.name;
    }
    if (selected === "primerySubject") {
      const text = e.name;
      temp[index][selected] = text;
      const findsing_prog = major_subjects.find((data) => data.name === text);
      const value = findsing_prog?.code;
      temp[index].primerySubjectCIPCODE = value;
      const filteradd1 = major_subjects.filter(
        (data) => data.name !== text.name
      );
      setAdditionalSubject1([...filteradd1]);
    }
    if (selected === "AdditionalSubject1") {
      const text = e.name;
      temp[index][selected] = text;
      const findsing_prog = major_subjects.find((data) => data.name === text);
      const value = findsing_prog.code;
      temp[index].AdditionalSubject1CIPCODE = value;

      const filteradd2 = major_subjects.filter(
        (data) => data.name !== text.name
      );
      setAdditionalSubject2([...filteradd2]);
    }
    if (selected === "AdditionalSubject2") {
      const text = e.name;
      temp[index][selected] = text;
      const findsing_prog = major_subjects.find((data) => data.name === text);
      const value = findsing_prog.code;
      temp[index].AdditionalSubject2CIPCODE = value;
    }
    setGraduationOption(temp);
  };

  const removeRowClickQuestion = (e, crrentindex) => {
    const optionList = [...GraduationOption];
    const filterdata = optionList.filter(
      (data, index) => index !== crrentindex
    );
    setGraduationOption(filterdata);
  };

  const handleNewRowQuestion = () => {
    const lastIndex = GraduationOption.length - 1;

    setGraduationOptionIndex("");
    setGraduationOptioncountry("");
    setGraduationOptioninstitute("");
    setGraduationOptioncity("");
    setGraduationOptiondegree("");
    setGraduationOptiongraduationMonth("");
    setGraduationOptiongraduationYear("");
    setGraduationOptionscoringType("");
    setGraduationOptiongpa("");
    setGraduationOptionMajorSubject("");

    if (GraduationOption[lastIndex].country === "") {
      setGraduationOptionIndex(lastIndex);
      setGraduationOptioncountry("Required");
      setGraduationOptionBtnPress(true);
    }
    if (GraduationOption[lastIndex].institute === "") {
      setGraduationOptionIndex(lastIndex);
      setGraduationOptioninstitute("Required");
      setGraduationOptionBtnPress(true);
    }
    if (GraduationOption[lastIndex].city === "") {
      setGraduationOptionIndex(lastIndex);
      setGraduationOptioncity("Required");
      setGraduationOptionBtnPress(true);
    }
    if (GraduationOption[lastIndex].degree === "") {
      setGraduationOptionIndex(lastIndex);
      setGraduationOptiondegree("Required");
      setGraduationOptionBtnPress(true);
    }
    if (GraduationOption[lastIndex].graduationMonth === "") {
      setGraduationOptionIndex(lastIndex);
      setGraduationOptiongraduationMonth("Required");
      setGraduationOptionBtnPress(true);
    }
    if (GraduationOption[lastIndex].graduationYear === "") {
      setGraduationOptionIndex(lastIndex);
      setGraduationOptiongraduationYear("Required");
      setGraduationOptionBtnPress(true);
    }
    if (GraduationOption[lastIndex].scoringType === "") {
      setGraduationOptionIndex(lastIndex);
      setGraduationOptionscoringType("Required");
      setGraduationOptionBtnPress(true);
    }
    if (GraduationOption[lastIndex].gpa === "") {
      setGraduationOptionIndex(lastIndex);
      setGraduationOptiongpa("Required");
      setGraduationOptionBtnPress(true);
    } else {
      setGraduationOptiongpa("");
    }
    if (GraduationOption[lastIndex].primerySubject === "") {
      setGraduationOptionIndex(lastIndex);
      setGraduationOptionMajorSubject("Required");
      setGraduationOptionBtnPress(true);
    } else {
      setGraduationOptionMajorSubject("");
    }

    if (
      GraduationOption[lastIndex].country === "" ||
      GraduationOption[lastIndex].institute === "" ||
      GraduationOption[lastIndex].city === "" ||
      GraduationOption[lastIndex].degree === "" ||
      GraduationOption[lastIndex].graduationMonth === "" ||
      GraduationOption[lastIndex].scoringType === "" ||
      GraduationOption[lastIndex].gpa === "" ||
      GraduationOption[lastIndex].primerySubject === "" ||
      GraduationOption[lastIndex].graduationYear === ""
    ) {
      setGraduationOptionIndex(lastIndex);
      setGraduationOptionBtnPress(true);
    } else {
      setGraduationOptionIndex("");
      setGraduationOptionBtnPress(false);
      setGraduationOption([
        ...GraduationOption,
        {
          country: "",
          institute: "",
          city: "",
          degree: "",
          graduationMonth: "",
          graduationYear: "",
          scoringType: "Grade",
          Scoring: "",
          gpa: "",
        },
      ]);
    }
  };

  const studentUpdateForm = async () => {
    if (studentDetail.currentFormStep > 4) {
      dispatch(dashPageStatuseChange(5));
    } else {
      const formData = {
        _id: loginData._id,
        education: {
          Educational: Educational,
          agencyName: agencyName,
          Reference: Reference,
          GraduationOption: GraduationOption,
        },
        educationStatus: true,
        currentFormStep: 5,
      };
      const student = await dispatch(updateStudent(formData));
      if (student.payload.success) {
        dispatch(setEducationalEvaluation(formData));
        dispatch(dashPageStatuseChange(5));
      }
    }
  };

  const educationalEvaluationClick = async () => {
    setbuttonPress(true);

    if (Educational === true) {
      if (agencyName === "") {
        setAgencyNameError("Required");
      } else {
        setAgencyNameError("");
      }
      if (Reference === "") {
        setCountryError("Required");
      } else {
        setCountryError("");
      }
    }

    let lastIndex = GraduationOption.length - 1;
    if (GraduationOption[lastIndex].country === "") {
      setGraduationOptionIndex(lastIndex);
      setGraduationOptioncountry("Required");
      setGraduationOptionBtnPress(true);
    } else {
      setGraduationOptioncountry("");
    }
    if (GraduationOption[lastIndex].institute === "") {
      setGraduationOptionIndex(lastIndex);
      setGraduationOptioninstitute("Required");
      setGraduationOptionBtnPress(true);
    } else {
      setGraduationOptioninstitute("");
    }
    if (GraduationOption[lastIndex].city === "") {
      setGraduationOptionIndex(lastIndex);
      setGraduationOptioncity("Required");
      setGraduationOptionBtnPress(true);
    } else {
      setGraduationOptioncity("");
    }
    if (GraduationOption[lastIndex].degree === "") {
      setGraduationOptionIndex(lastIndex);
      setGraduationOptiondegree("Required");
      setGraduationOptionBtnPress(true);
    } else {
      setGraduationOptiondegree("");
    }
    if (GraduationOption[lastIndex].graduationMonth === "") {
      setGraduationOptionIndex(lastIndex);
      setGraduationOptiongraduationMonth("Required");
      setGraduationOptionBtnPress(true);
    } else {
      setGraduationOptiongraduationMonth("");
    }
    if (GraduationOption[lastIndex].graduationYear === "") {
      setGraduationOptionIndex(lastIndex);
      setGraduationOptiongraduationYear("Required");
      setGraduationOptionBtnPress(true);
    } else {
      setGraduationOptiongraduationYear("");
    }
    if (GraduationOption[lastIndex].scoringType === "") {
      setGraduationOptionIndex(lastIndex);
      setGraduationOptionscoringType("Required");
      setGraduationOptionBtnPress(true);
    } else {
      setGraduationOptionscoringType("");
    }
    if (GraduationOption[lastIndex].gpa === "") {
      setGraduationOptionIndex(lastIndex);
      setGraduationOptiongpa("Required");
      setGraduationOptionBtnPress(true);
    } else {
      setGraduationOptiongpa("");
    }
    if (GraduationOption[lastIndex].primerySubject === "") {
      setGraduationOptionIndex(lastIndex);
      setGraduationOptionMajorSubject("Required");
      setGraduationOptionBtnPress(true);
    } else {
      setGraduationOptionMajorSubject("");
    }

    if (
      GraduationOption[lastIndex].country !== "" &&
      GraduationOption[lastIndex].institute !== "" &&
      GraduationOption[lastIndex].city !== "" &&
      GraduationOption[lastIndex].degree !== "" &&
      GraduationOption[lastIndex].graduationMonth !== "" &&
      GraduationOption[lastIndex].scoringType !== "" &&
      GraduationOption[lastIndex].gpa !== "" &&
      GraduationOption[lastIndex].primerySubject !== "" &&
      GraduationOption[lastIndex].graduationYear !== ""
    ) {
      if (Educational === true) {
        if (agencyName !== "" && Reference !== "") {
          await studentUpdateForm();
        } else {
          setbuttonPress(true);
          setGraduationOptionBtnPress(true);
        }
      } else {
        await studentUpdateForm();
      }
    } else {
      setbuttonPress(true);
      setGraduationOptionBtnPress(true);
    }
  };

  return (
    <>
      <Modal
        title=""
        open={info_model}
        // onOk={() => setinfo_model(false)}
        onCancel={() => setinfo_model(false)}
        footer={null}
        maskClosable={false}
      >
        <p className="mt-4 education_model_text">
          To seek admission to colleges or universities in the US, individuals
          must have their qualifications and certificates evaluated by
          NASCES-accredited members like WES. This educational evaluation will
          be sent directly to the college or university where admission is
          sought. This is a mandatory requirement for all admissions in the USA.{" "}
          <br />
          example GPA 3.5, 4.0
        </p>
      </Modal>
      <div className="main_container">
        <div className="container_box">
          <Header
            title="Education"
            description="Please enter your educational details"
          />
          <div className="inputs_container">
            <div className="radio_div_container">
              <span className="radio_title">
                Did you start the process of your educational evaluation
                <FaInfoCircle
                  className="mx-2 my-1 currsor_po"
                  // data-tooltip-id="my-tooltip-1"
                  onClick={() => setinfo_model(true)}
                />
              </span>
              <Radio.Group defaultValue={false}>
                <Radio
                  value={true}
                  checked={Educational === true}
                  onClick={() => {
                    setEducational(true);
                  }}
                >
                  Yes
                </Radio>
                <Radio
                  value={false}
                  checked={Educational === false}
                  onClick={() => {
                    setEducational(false);
                    setbuttonPress(false);
                    setAgencyNameError("");
                    setCountryError("");
                  }}
                >
                  No
                </Radio>
              </Radio.Group>
            </div>
            {Educational === true && (
              <>
                <div className="dn_input_box">
                  <TextField
                    label="Educational evaluation agency name"
                    type="text"
                    id="text"
                    className="input_style"
                    error={agencyNameError !== "" ? true : false}
                    helperText={agencyNameError}
                    value={agencyName}
                    onChange={(e) => setAgencyName(e.target.value)}
                    autocomplete="off"
                    size="small"
                  />
                </div>
                <div className="dn_input_box">
                  <TextField
                    label="Reference Number"
                    type="text"
                    id="text"
                    className="input_style"
                    error={countryError !== "" ? true : false}
                    helperText={countryError}
                    value={Reference}
                    onChange={(e) => setReference(e.target.value)}
                    autocomplete="off"
                    size="small"
                  />
                </div>
              </>
            )}

            <div className="radio_div_container">
              <Typography variant="h5" component="h5">
                Qualification(s)
              </Typography>
              <Divider variant="middle" />
            </div>

            {GraduationOption.map((graduation, index) => (
              <>
                <div className="dn_input_box">
                  <TextField
                    label="University/College Name"
                    type="text"
                    id="text"
                    className="input_style"
                    error={
                      GraduationOptioninstitute !== "" &&
                      GraduationOptionIndex === index
                        ? true
                        : false
                    }
                    helperText={
                      GraduationOptionIndex === index
                        ? GraduationOptioninstitute
                        : ""
                    }
                    onChange={(e) =>
                      handleCheckGraduation(index, e, "institute")
                    }
                    value={graduation?.institute}
                    autocomplete="off"
                    size="small"
                  />
                </div>
                <div className="dn_input_box">
                  <TextField
                    id="select"
                    label="Diploma/Degree"
                    select
                    className="input_style"
                    error={
                      GraduationOptiondegree !== "" &&
                      GraduationOptionIndex === index
                        ? true
                        : false
                    }
                    helperText={
                      GraduationOptionIndex === index
                        ? GraduationOptiondegree
                        : ""
                    }
                    value={graduation?.degree}
                    onChange={(e) => handleCheckGraduation(index, e, "degree")}
                    size="small"
                  >
                    {diplomaList.map((data, index) => (
                      <MenuItem key={index} value={data.name}>
                        {data.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className="dn_input_box">
                  {/* <TextField
                    id="select"
                    label="Country"
                    select
                    className="input_style"
                    error={
                      GraduationOptioncountry !== "" &&
                      GraduationOptionIndex === index
                        ? true
                        : false
                    }
                    helperText={
                      GraduationOptionIndex === index
                        ? GraduationOptioncountry
                        : ""
                    }
                    value={graduation?.country}
                    onChange={(e) => handleCheckGraduation(index, e, "country")}
                    size="small"
                  >
                    {countries.map((data, index) => (
                      <MenuItem key={index} value={data.name}>
                        {data.name}
                      </MenuItem>
                    ))}
                  </TextField> */}

                  <Autocomplete
                    options={countries}
                    getOptionLabel={(option) => option.name}
                    value={
                      countries.find((c) => c.name === graduation?.country) ||
                      null
                    }
                    onChange={(event, newValue) => {
                      if (newValue) {
                        handleCheckGraduation(index, newValue, "country");
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Country"
                        error={
                          GraduationOptioncountry !== "" &&
                          GraduationOptionIndex === index
                            ? true
                            : false
                        }
                        helperText={
                          GraduationOptionIndex === index
                            ? GraduationOptioncountry
                            : ""
                        }
                        size="small"
                      />
                    )}
                    className="input_style"
                  />
                </div>
                <div className="dn_input_box">
                  <TextField
                    id="select"
                    label="City"
                    select
                    className="input_style"
                    error={
                      GraduationOptioncity !== "" &&
                      GraduationOptionIndex === index
                        ? true
                        : false
                    }
                    helperText={
                      GraduationOptionIndex === index
                        ? GraduationOptioncity
                        : ""
                    }
                    value={graduation?.city}
                    onChange={(e) => handleCheckGraduation(index, e, "city")}
                    size="small"
                  >
                    {showCityByCountry.map((data, index) => (
                      <MenuItem key={index} value={data.name}>
                        {data.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className="dn_input_box">
                  <div className="drop_small_container">
                    <TextField
                      id="select"
                      label="Month Graduated or Expected"
                      select
                      className="dropd_small_input_style_two"
                      error={
                        GraduationOptiongraduationMonth !== "" &&
                        GraduationOptionIndex === index
                          ? true
                          : false
                      }
                      helperText={
                        GraduationOptionIndex === index
                          ? GraduationOptiongraduationMonth
                          : ""
                      }
                      value={graduation?.graduationMonth}
                      onChange={(e) =>
                        handleCheckGraduation(index, e, "graduationMonth")
                      }
                      size="small"
                    >
                      {selectMonth.map((year, index) => (
                        <MenuItem key={index} value={year.name}>
                          {year.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      id="select"
                      label="Year"
                      select
                      className="dropd_small_input_style_two"
                      error={
                        GraduationOptiongraduationYear !== "" &&
                        GraduationOptionIndex === index
                          ? true
                          : false
                      }
                      helperText={
                        GraduationOptionIndex === index
                          ? GraduationOptiongraduationYear
                          : ""
                      }
                      value={graduation?.graduationYear}
                      onChange={(e) =>
                        handleCheckGraduation(index, e, "graduationYear")
                      }
                      size="small"
                    >
                      {SelectYear.map((year, index) => (
                        <MenuItem key={index} value={year.name}>
                          {year.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>
                <div className="dn_input_box">
                  <div
                    className="input_style"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <span className="radio_title">Grading System</span>

                    <Radio.Group defaultValue="Grade">
                      <Radio
                        value="Grade"
                        checked={graduation?.scoringType === "Grade"}
                        onClick={(e) => {
                          handleCheckGraduation(
                            index,
                            e,
                            "scoringType",
                            "Grade"
                          );
                        }}
                      >
                        Grade
                      </Radio>
                      <Radio
                        value="CGPA"
                        checked={graduation?.scoringType === "CGPA"}
                        onClick={(e) =>
                          handleCheckGraduation(index, e, "scoringType", "CGPA")
                        }
                      >
                        CGPA
                      </Radio>
                      <Radio
                        value="Percentage"
                        checked={graduation?.scoringType === "Percentage"}
                        onClick={(e) =>
                          handleCheckGraduation(
                            index,
                            e,
                            "scoringType",
                            "Percentage"
                          )
                        }
                      >
                        Percentage
                      </Radio>
                      <Radio
                        value="GPA"
                        checked={graduation?.scoringType === "GPA"}
                        onClick={(e) =>
                          handleCheckGraduation(index, e, "scoringType", "GPA")
                        }
                      >
                        GPA
                      </Radio>
                    </Radio.Group>
                  </div>
                </div>
                {graduation?.scoringType === "Grade" && (
                  <div className="dn_input_box">
                    <TextField
                      id="select"
                      label={graduation?.scoringType}
                      select
                      className="input_style"
                      error={
                        GraduationOptiongpa !== "" &&
                        GraduationOptionIndex === index
                      }
                      helperText={
                        GraduationOptionIndex === index
                          ? GraduationOptiongpa
                          : ""
                      }
                      value={graduation?.gpa}
                      onChange={(e) => handleCheckGraduation(index, e, "gpa")}
                      size="small"
                    >
                      {selectGPAGrade.map((data, index) => (
                        <MenuItem key={index} value={data.cgp}>
                          {data.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                )}
                {graduation?.scoringType === "CGPA" && (
                  <div className="dn_input_box">
                    <TextField
                      id="select"
                      label={graduation?.scoringType}
                      select
                      className="input_style"
                      error={
                        GraduationOptiongpa !== "" &&
                        GraduationOptionIndex === index
                      }
                      helperText={
                        GraduationOptionIndex === index
                          ? GraduationOptiongpa
                          : ""
                      }
                      value={graduation?.gpa}
                      onChange={(e) => handleCheckGraduation(index, e, "gpa")}
                      size="small"
                    >
                      {selectGPACgp.map((data, index) => (
                        <MenuItem key={index} value={data.cgp}>
                          {data.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                )}
                {graduation?.scoringType === "GPA" && (
                  <div className="dn_input_box">
                    <TextField
                      id="select"
                      label={graduation?.scoringType}
                      select
                      className="input_style"
                      error={
                        GraduationOptiongpa !== "" &&
                        GraduationOptionIndex === index
                      }
                      helperText={
                        GraduationOptionIndex === index
                          ? GraduationOptiongpa
                          : ""
                      }
                      value={graduation?.gpa}
                      onChange={(e) => handleCheckGraduation(index, e, "gpa")}
                      size="small"
                    >
                      {selectGPA.map((data, index) => (
                        <MenuItem key={index} value={data.cgp}>
                          {data.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                )}
                {graduation?.scoringType === "Percentage" && (
                  <div className="dn_input_box">
                    <TextField
                      id="select"
                      label={graduation?.scoringType}
                      select
                      className="input_style"
                      error={
                        GraduationOptiongpa !== "" &&
                        GraduationOptionIndex === index
                      }
                      helperText={
                        GraduationOptionIndex === index
                          ? GraduationOptiongpa
                          : ""
                      }
                      value={graduation?.gpa}
                      onChange={(e) => handleCheckGraduation(index, e, "gpa")}
                      size="small"
                    >
                      {selectGPAPercentage.map((data, index) => (
                        <MenuItem key={index} value={data.cgp}>
                          {data.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                )}
                <div className="dn_input_box">
                  <TextField
                    label="GPA"
                    type="text"
                    id="text"
                    className="input_style"
                    value={graduation?.gpa}
                    disabled={true}
                    autocomplete="off"
                    size="small"
                  />
                </div>
                {"primerySubject" in graduation && (
                  <div className="dn_input_box">
                    <Autocomplete
                      options={major_subjects
                        .filter(
                          (data) =>
                            data.name !== graduation?.AdditionalSubject2 &&
                            data.name !== graduation?.AdditionalSubject1
                        )
                        .sort((a, b) => (a.name > b.name ? 1 : -1))}
                      getOptionLabel={(option) => option.name}
                      value={
                        major_subjects.find(
                          (c) => c.name === graduation?.primerySubject
                        ) || null
                      }
                      onChange={(event, newValue) => {
                        if (newValue) {
                          handleCheckGraduation(
                            index,
                            newValue,
                            "primerySubject"
                          );
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Major"
                          error={
                            GraduationOptionMajorSubject !== "" &&
                            GraduationOptionIndex === index
                              ? true
                              : false
                          }
                          helperText={
                            GraduationOptionIndex === index
                              ? GraduationOptionMajorSubject
                              : ""
                          }
                          size="small"
                        />
                      )}
                      className="input_style"
                    />
                  </div>
                )}
                {"AdditionalSubject1" in graduation && (
                  <>
                    <div className="dn_input_box">
                      <div className="input_style"></div>
                    </div>
                    <div className="radio_div_container">
                      <span className="option_major">
                        Opting for additional majors will expand the selection
                        of universities compatible with your profile.
                      </span>
                    </div>
                    <div className="dn_input_box">
                      <Autocomplete
                        options={AdditionalSubject1.filter(
                          (data) =>
                            data.name !== graduation?.AdditionalSubject2 &&
                            data.name !== graduation?.primerySubject
                        ).sort((a, b) => (a.name > b.name ? 1 : -1))}
                        getOptionLabel={(option) => option.name}
                        value={
                          AdditionalSubject1.find(
                            (c) => c.name === graduation?.AdditionalSubject1
                          ) || null
                        }
                        onChange={(event, newValue) => {
                          if (newValue) {
                            handleCheckGraduation(
                              index,
                              newValue,
                              "AdditionalSubject1"
                            );
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label=" 2nd Major"
                            size="small"
                          />
                        )}
                        className="input_style"
                      />
                    </div>
                    <div className="dn_input_box">
                      <Autocomplete
                        options={AdditionalSubject2.filter(
                          (data) =>
                            data.name !== graduation?.AdditionalSubject1 &&
                            data.name !== graduation?.primerySubject
                        ).sort((a, b) => (a.name > b.name ? 1 : -1))}
                        getOptionLabel={(option) => option.name}
                        value={
                          AdditionalSubject2.find(
                            (c) => c.name === graduation?.AdditionalSubject2
                          ) || null
                        }
                        onChange={(event, newValue) => {
                          if (newValue) {
                            handleCheckGraduation(
                              index,
                              newValue,
                              "AdditionalSubject2"
                            );
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="3rd Major"
                            size="small"
                          />
                        )}
                        className="input_style"
                      />
                    </div>
                  </>
                )}

                {GraduationOption.length !== 1 && (
                  <>
                    <div className="button_container">
                      <Button
                        type="primary"
                        // loading={loading}
                        iconPosition="end"
                        icon={<PiPaperPlaneRight />}
                        size="large"
                        onClick={(e) => removeRowClickQuestion(e, index)}
                        className="add_more_btn"
                      >
                        Remove
                      </Button>
                    </div>
                  </>
                )}
              </>
            ))}
            {GraduationOption.length !== 3 && (
              <>
                <div className="button_containe">
                  <Button
                    type="primary"
                    iconPosition="end"
                    icon={<PiPaperPlaneRight />}
                    size="large"
                    onClick={handleNewRowQuestion}
                    className="add_more_btn"
                  >
                    Add More
                  </Button>
                </div>
              </>
            )}
            <div className="button_container_distance">
              <Button
                type="primary"
                iconPosition="start"
                icon={<HiChevronDoubleLeft />}
                size="large"
                onClick={() => dispatch(dashPageStatuseChange(3))}
                className="back_btn_btn"
              >
                Back
              </Button>
              <Button
                type="primary"
                // loading={loading}
                iconPosition="end"
                icon={<PiPaperPlaneRight />}
                size="large"
                onClick={() => educationalEvaluationClick()}
              >
                Save & Next
              </Button>
            </div>
          </div>
        </div>
        <ReactTooltip
          id="my-tooltip-1"
          place="top"
          variant="info"
          content="To seek admission to colleges or universities in the US, individuals must have their qualifications and certificates evaluated by NASCES-accredited members like WES. This educational evaluation will be sent directly to the college or university where admission is sought. This is a mandatory requirement for all admissions in the USA. "
          className="tooltip_style"
        />
      </div>
    </>
  );
};

export default Education;
