import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import { TextField } from "@mui/material";
import { Typography, Radio, Col, Button, Space, Modal } from "antd";
import { PiPaperPlaneRight } from "react-icons/pi";
import { HiChevronDoubleLeft } from "react-icons/hi2";
import countries from "../json_datas/countries.json";
import Header from "./Header";
import { updateStudent } from "../../../redux/StudentRegd/StudentRegdSlice";
import {
  dashPageStatuseChange,
  setFinancialSupport,
  setOtherNSecurity,
} from "../../../redux/dashboard/PageRenderSlice";

const OtherInformation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { currentFormStep, studentDetail } = useSelector(
    (store) => store.StudentRegd
  );
  const { loginData } = useSelector((store) => store.Athentication);

  const [medicalCondition, setMedicalCondition] = useState(false);
  const [drugabuser, setDrugabuser] = useState(false);
  const [arrestforcrime, setArrestforcrime] = useState(false);
  const [contributionToChartitable, setContributionToChartitable] =
    useState(false);
  const [specializeSkill, setSpecializeSkill] = useState(false);
  const [militaryServe, setMilitaryServe] = useState(false);
  const [paramilitaryServe, setParamilitaryServe] = useState(false);
  const [postAgainstUSA, setPostAgainstUSA] = useState(false);

  // Error Message
  const [medicalConditionError, setMedicalConditionError] = useState("");
  const [drugabuserError, setDrugabuserError] = useState("");
  const [arrestforcrimeError, setArrestforcrimeError] = useState("");
  const [contributionToChartitableError, setContributionToChartitableError] =
    useState("");
  const [specializeSkillError, setSpecializeSkillError] = useState("");
  const [militaryServeError, setMilitaryServeError] = useState("");
  const [paramilitaryServeError, setParamilitaryServeError] = useState("");
  const [postAgainstUSAError, setPostAgainstUSAError] = useState("");

  const [AllertMassage, setAllertMassage] = useState("");

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const modelclose = () => {
    setModal(false);
  };
  const notificationPress = (massage) => {
    Modal.warning({
      title: "This is a warning message",
      content: `${massage}`,
      onOk() {},
      okText: "Got It",
    });
  };

  useEffect(() => {
    if (studentDetail.currentFormStep > 7) {
      setMedicalCondition(studentDetail.otherInformation.medicalCondition);
      setDrugabuser(studentDetail.otherInformation.drugabuser);
      setArrestforcrime(studentDetail.otherInformation.arrestforcrime);
      setContributionToChartitable(
        studentDetail.otherInformation.contributionToChartitable
      );
      setSpecializeSkill(studentDetail.otherInformation.specializeSkill);
      setMilitaryServe(studentDetail.otherInformation.militaryServe);
      setParamilitaryServe(studentDetail.otherInformation.paramilitaryServe);
      setPostAgainstUSA(studentDetail.otherInformation.postAgainstUSA);
    }
  }, [studentDetail]);

  const OtherSecurityClick = async () => {
    if (studentDetail.currentFormStep > 8) {
      dispatch(dashPageStatuseChange(9));
    } else {
      try {
        const formData = {
          _id: loginData._id,
          otherInformation: {
            medicalCondition: medicalCondition,
            drugabuser: drugabuser,
            arrestforcrime: arrestforcrime,
            contributionToChartitable: contributionToChartitable,
            specializeSkill: specializeSkill,
            militaryServe: militaryServe,
            paramilitaryServe: paramilitaryServe,
            postAgainstUSA: postAgainstUSA,
          },
          currentFormStep: 9,
          otherInformationStatus: true,
        };
        const student = await dispatch(updateStudent(formData));
        if (student.payload.success) {
          dispatch(setOtherNSecurity(formData));
          if (loginData.subscriptionstatus === true) {
            dispatch(dashPageStatuseChange(9));
          } else {
            dispatch(dashPageStatuseChange(9));
            navigate("/subscription");
          }
        }
      } catch (error) {}
    }
  };

  return (
    <>
      <div className="main_container">
        <div className="container_box">
          <Header title="Additional Information" description="Please answer" />
          <div className="inputs_container">
            <div className="radio_div_container">
              <span className="radio_title">
                Do you have any chronic or congenital medical condition?
              </span>
              <Radio.Group defaultValue={false}>
                <Radio
                  value={true}
                  checked={medicalCondition === true}
                  onClick={() => {
                    setMedicalCondition(true);
                    setAllertMassage(
                      "Medical condition is not a disqualification but the treatment costs are very high in the US. This will add to the funding support you require."
                    );
                    notificationPress(
                      "Medical condition is not a disqualification but the treatment costs are very high in the US. This will add to the funding support you require."
                    );
                  }}
                >
                  Yes
                </Radio>
                <Radio
                  value={false}
                  checked={medicalCondition === false}
                  onClick={() => {
                    setMedicalCondition(false);
                  }}
                >
                  No
                </Radio>
              </Radio.Group>
            </div>
            <div className="radio_div_container">
              <span className="radio_title">
                Are you or have you ever been a drug abuser or addict?
              </span>
              <Radio.Group defaultValue={false}>
                <Radio
                  value={true}
                  checked={drugabuser === true}
                  onClick={() => {
                    setDrugabuser(true);
                    setAllertMassage(
                      "US Visa authorities and US colleges may view drug abuse or addiction unfavorably."
                    );
                    notificationPress(
                      "US Visa authorities and US colleges may view drug abuse or addiction unfavorably."
                    );
                  }}
                >
                  Yes
                </Radio>
                <Radio
                  value={false}
                  checked={drugabuser === false}
                  onClick={() => {
                    setDrugabuser(false);
                  }}
                >
                  No
                </Radio>
              </Radio.Group>
            </div>
            <div className="radio_div_container">
              <span className="radio_title">
                Have you ever been arrested or convicted for any offense or
                crime?
              </span>
              <Radio.Group defaultValue={false}>
                <Radio
                  value={true}
                  checked={arrestforcrime === true}
                  onClick={() => {
                    setArrestforcrime(true);
                    setAllertMassage(
                      "US Visa authorities may consider a criminal background unfavorably."
                    );
                    notificationPress(
                      "US Visa authorities may consider a criminal background unfavorably."
                    );
                  }}
                >
                  Yes
                </Radio>
                <Radio
                  value={false}
                  checked={arrestforcrime === false}
                  onClick={() => {
                    setArrestforcrime(false);
                  }}
                >
                  No
                </Radio>
              </Radio.Group>
            </div>
            <div className="radio_div_container">
              <span className="radio_title">
                Have you belonged to, contributed to, or worked for any
                professional, social, or charitable organization?
              </span>
              <Radio.Group defaultValue={false}>
                <Radio
                  value={true}
                  checked={contributionToChartitable === true}
                  onClick={() => {
                    setContributionToChartitable(true);
                    setAllertMassage("");
                  }}
                >
                  Yes
                </Radio>
                <Radio
                  value={false}
                  checked={contributionToChartitable === false}
                  onClick={() => {
                    setContributionToChartitable(false);
                  }}
                >
                  No
                </Radio>
              </Radio.Group>
            </div>
            <div className="radio_div_container">
              <span className="radio_title">
                Do you have any specialized skills or training, such as
                firearms, explosives, nuclear, biological, or chemical
                experience?
              </span>
              <Radio.Group defaultValue={false}>
                <Radio
                  value={true}
                  checked={specializeSkill === true}
                  onClick={() => {
                    setSpecializeSkill(true);
                    setAllertMassage(
                      "Skills in these areas may prompt heightened scrutiny or raise concerns with US Visa authorities."
                    );
                    notificationPress(
                      "Skills in these areas may prompt heightened scrutiny or raise concerns with US Visa authorities."
                    );
                  }}
                >
                  Yes
                </Radio>
                <Radio
                  value={false}
                  checked={specializeSkill === false}
                  onClick={() => {
                    setSpecializeSkill(false);
                  }}
                >
                  No
                </Radio>
              </Radio.Group>
            </div>
            <div className="radio_div_container">
              <span className="radio_title">
                Have you ever served in the military?
              </span>
              <Radio.Group defaultValue={false}>
                <Radio
                  value={true}
                  checked={militaryServe === true}
                  onClick={() => {
                    setMilitaryServe(true);
                    setAllertMassage(
                      "Military service may prompt heightened scrutiny or raise concerns with US Visa authorities."
                    );
                    notificationPress(
                      "Military service may prompt heightened scrutiny or raise concerns with US Visa authorities."
                    );
                  }}
                >
                  Yes
                </Radio>
                <Radio
                  value={false}
                  checked={militaryServe === false}
                  onClick={() => {
                    setMilitaryServe(false);
                  }}
                >
                  No
                </Radio>
              </Radio.Group>
            </div>
            <div className="radio_div_container">
              <span className="radio_title">
                Have you ever served in, been a member of, or been involved with
                a paramilitary unit, vigilante unit, rebel group, guerilla
                group, or insurgent organization? *
              </span>
              <Radio.Group defaultValue={false}>
                <Radio
                  value={true}
                  checked={paramilitaryServe === true}
                  onClick={() => {
                    setParamilitaryServe(true);
                    setAllertMassage(
                      "Such association may raise concerns with US Visa Authorities."
                    );
                    notificationPress(
                      "Such association may raise concerns with US Visa Authorities."
                    );
                  }}
                >
                  Yes
                </Radio>
                <Radio
                  value={false}
                  checked={paramilitaryServe === false}
                  onClick={() => {
                    setParamilitaryServe(false);
                  }}
                >
                  No
                </Radio>
              </Radio.Group>
            </div>
            <div className="radio_div_container">
              <span className="radio_title">
                Have you ever posted against USA policies or government in any
                of the social media platforms viz. facebook, twitter, instagram,
                etc.?
              </span>
              <Radio.Group defaultValue={false}>
                <Radio
                  value={true}
                  checked={postAgainstUSA === true}
                  onClick={() => {
                    setPostAgainstUSA(true);
                    setAllertMassage("");
                  }}
                >
                  Yes
                </Radio>
                <Radio
                  value={false}
                  checked={postAgainstUSA === false}
                  onClick={() => {
                    setPostAgainstUSA(false);
                  }}
                >
                  No
                </Radio>
              </Radio.Group>
            </div>
            <div className="button_container_distance">
              <Button
                type="primary"
                iconPosition="start"
                icon={<HiChevronDoubleLeft />}
                size="large"
                onClick={() => dispatch(dashPageStatuseChange(7))}
                 className="back_btn_btn"
              >
                Back
              </Button>
              <Button
                type="primary"
                // loading={loading}
                iconPosition="end"
                icon={<PiPaperPlaneRight />}
                size="large"
                onClick={() => {
                  OtherSecurityClick();
                }}
              >
                Save & Next
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OtherInformation;
