import React from 'react'

const HowItWorks = () => {
  return (
    <>
      <div className="maincontPart">
        <div className="headingPart">
          <div className="head">
            <h4>How it works?</h4>
            <div className="para">
              <p>F1VASA. online is a user-friendly web
                platform designed to help international
                students in their quest for admission to
                US universities. International Students,
                who meet the criteria of both US colleges/Universities
                and the F1 student visa, can greatly benefit from our
                affordable and transparent processes. By creating their
                profile with relevant information, prospective
                international students can access customized program
                recommendations, thereby amplifying their chances of
                securing enrollment
                acceptance</p>
            </div>
          </div>
          {/* <div className="btnnPart">
            <button>Watch <br /> 'How it works' <br /> video</button>
          </div> */}
        </div>

        <div className="diffpart">
          <div className="insidepart">
            <div className="box1">
              <img src="../assets/img/picc1.png" alt="" className='Imagee'>
              </img>
              <div className="box1_one">
                <p className='box_text'>Traditional 'Educational Counseling' Path for International Students</p>
              </div>
            </div>
            <div className="box2">
              <h6>VS.</h6>
            </div>
            <div className="box1">
              <img src="../assets/img/picc2.png" alt="" className='Imagee'></img>
              <div className="box1_one">
                <p className='box_text'>F1VISA.online process</p>
              </div>
            </div>

          </div>
        </div>
        <div className="path_container">
          <img src="../assets/img/F1VISA_updated.svg" alt="" className='path_image' />

        </div>
        {/* <div className="diffpart">
          <div className="insidepart">
            <div className="box1">
              <img src="../assets/img/picc1.png" alt="" className='Imagee'>
              </img>
              <div className="box1_one">
            <p className='box_text'>Traditional 'Educational Counseling' Path for International Students</p>
            </div>
            </div>
            <div className="box2">
              <h6>VS.</h6>
            </div>
            <div className="box1">
              <img src="../assets/img/picc2.png" alt="" className='Imagee'></img>
              <div className="box1_one">
            <p className='box_text'>F1VISA.online process</p>
          </div>
            </div>

          </div>
        </div>
        <div className="path_container">
          <img src="../assets/img/howtoworkk.png" alt="" className='path_image' />
          <div className="path_one">
            <p className='path_text'>Student approaches an Educational Consultant (may require visiting their office or traveling if they do not have a location nearby)</p>
          </div>
          <div className="path_one1">
            <p className='path_text'>After paying a huge sum as consultation fee, the consultant assesses the student (solely depending on the consultant's capabilities)</p>
          </div>
          <div className="path_one2">
            <p className='path_text'>Consultant assists in completing application (writing essay, preparing resumes, and other docs)</p>
          </div>
          <div className="path_one3">
            <p className='path_text'>Consultant charges more in the name other services (test preperations, financial documents, applications, etc)</p>
          </div>
          <div className="path_one4">
            <p className='path_text'>Student has to run from pillar to post in securing the documents (not knowing where to obtain from, consuming lots of time)</p>
          </div>
          <div className="path_one5">
            <p className='path_text'>Student has to run from pillar to post in securing the documents (not knowing where to obtain from, consuming lots of time)</p>
          </div>
          <div className="path_one6">
            <p className='path_text'>Student gets Admission (consultant's role ends here)</p>
          </div>
          <div className="path_one7">
            <p className='path_text'>Student joins the program (and realises better-suited program is offered elsewhere)</p>
          </div>
          <div className="path_one8">
            <p className='path_text'>Few will move to join the new program (wasting lots of time snf money)</p>
          </div>
          <div className="path_one9">
            <p className='path_text'>Student on his/her own (no support in the US)</p>
          </div>
          <div className="path_one1_1">
            <p className='path_text1'>Student creates profile and enter preferences (transparent subscription options-20/30/50 USD)</p>
          </div>
          <div className="path_one1_2">
            <p className='path_text1'>Student completes all processes at own pace and can even apply directly to certain institutions
            (all workflow-driven with timely notifications and technology-driven guidance)</p>
          </div>
          <div className="path_one1_3">
            <p className='path_text1'>Post-Admission Supportin the USA</p>
          </div>
        </div> */}
      </div>
    </>
  )
}

export default HowItWorks
