import React, { useState, useEffect, useRef } from "react";
import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, Space, Alert, Button } from "antd";
import {
  authActions,
  studentRegistation,
} from "../redux/authentication/AuthenticationSlice";

const Password = () => {
  const ref = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { studentRegdInfo } = useSelector((store) => store.StudentRegd);

  const [password, setPassword] = useState("");
  const [currectpassword, setCurrectPassword] = useState("");
  const [cnfPassword, setcnfPassword] = useState("");
  const [mathedPassword, setmathedPassword] = useState("");
  const [btnPress, setbtnPress] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showCnfPassword, setShowCnfPassword] = useState(false);

  const [passwordError, setPasswordError] = useState("");
  const [cnfPasswordError, setcnfPasswordError] = useState("");
  const [errorArray, seterrorArray] = useState([]);
  const [passwordmatchError, setcnfPasswordmatchError] = useState("");

  const [open, setOpen] = useState(false);
  const [buttonloading, setbuttonloading] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowCnfPassword = () => setShowCnfPassword((show) => !show);

  useEffect(() => {
    if (btnPress === true) {
      if (currectpassword === "") {
        setPasswordError("Please enter password");
      } else {
        setPasswordError("");
      }
      if (mathedPassword !== currectpassword || currectpassword === "") {
        setcnfPasswordError("Both passwords do not match");
      } else {
        setcnfPasswordError("");
      }
    }
  }, [btnPress, currectpassword, mathedPassword]);

  useEffect(() => {
    const clickoutside = (e) => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("click", clickoutside);
    return () => {
      document.addEventListener("click", clickoutside);
    };
  }, [open]);

  const changePassword = (e) => {
    setOpen(true);
    const currpassword = e.target.value;
    setPassword(currpassword);
    const lengthRegex = /^.{8,}$/;
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const specialCharacterRegex = /[!@#$%^&*()_+]/;
    const numberRegex = /[0-9]/;
    let errorarray = [];
    if (uppercaseRegex.test(currpassword)) {
      errorarray = [...errorarray, "uppercase"];
    }
    if (lowercaseRegex.test(currpassword)) {
      errorarray = [...errorarray, "lowercase"];
    }
    if (specialCharacterRegex.test(currpassword)) {
      errorarray = [...errorarray, "special"];
    }
    if (numberRegex.test(currpassword)) {
      errorarray = [...errorarray, "number"];
    }
    if (lengthRegex.test(currpassword)) {
      errorarray = [...errorarray, "8characters"];
    }

    if (
      uppercaseRegex.test(currpassword) &&
      lowercaseRegex.test(currpassword) &&
      numberRegex.test(currpassword) &&
      lengthRegex.test(currpassword) &&
      specialCharacterRegex.test(currpassword)
    ) {
      setCurrectPassword(currpassword);
      setOpen(false);
    } else {
      setOpen(true);
    }

    seterrorArray([...errorarray]);
  };

  const changeConfermPassword = (e) => {
    const currpassword = e.target.value;
    setcnfPassword(currpassword);
    if (currectpassword === currpassword) {
      setmathedPassword(currpassword);
      setcnfPasswordError("");
    } else {
      setmathedPassword("");
      setcnfPasswordError("Please Confirm your password");
    }
  };

  const items = [
    {
      label: errorArray.includes("uppercase") ? (
       <></>
      ) : (
        <>
          <span>Must contain at least one uppercase letter</span>
        </>
      ),
      key: "1",
    },
    {
      label: errorArray.includes("lowercase") ? (
        <></>
      ) : (
        <>
          <span>Must contain at least one lowercase letter</span>
        </>
      ),
      key: "2",
    },
    {
      label: errorArray.includes("special") ? (
        <></>
      ) : (
        <>
          <span>Must contain at least one special character</span>
        </>
      ),
      key: "3",
    },
    {
      label: errorArray.includes("number") ? (
        <></>
      ) : (
        <>
          <span>Must contain at least one number</span>
        </>
      ),
      key: "4",
    },
    {
      label: errorArray.includes("8characters") ? (
        <></>
      ) : (
        <>
          <span>Must contain at least 8 characters</span>
        </>
      ),
      key: "5",
    },
  ];

  const passwordClick = async (e) => {
    e.preventDefault();
    setbtnPress(true);
    setbuttonloading(true);

    if (currectpassword === "") {
      setPasswordError("Please enter password");
    } else {
      setPasswordError("");
    }
    if (mathedPassword !== currectpassword || currectpassword === "") {
      setcnfPasswordError("Both passwords do not match");
    } else {
      setcnfPasswordError("");
    }

    if (currectpassword === mathedPassword) {
      const formdata = {
        email: studentRegdInfo.email,
        firstname: studentRegdInfo.firstname,
        lastname: studentRegdInfo.lastname,
        birthdate: studentRegdInfo.birthdate,
        degreeName: studentRegdInfo.degreeName,
        questionResponse: studentRegdInfo.questionResponse,
        password: mathedPassword,
      };

      const StudentDetails = await dispatch(studentRegistation(formdata));

      if (StudentDetails.payload.success) {
        setbtnPress(false);
        setbuttonloading(false);
        <Alert
          message="Student registation successfully done"
          type="success"
          showIcon
        />;
        navigate("/sign-in");
      } else {
        // setRegError("Registation Un-Successfully");
        // setSubmiterror("red");
      }
    } else {
      setbtnPress(true);
      setbuttonloading(false);
      setcnfPasswordmatchError("Password does't match");
    }
    setbuttonloading(false);
  };

  return (
    <>
      <div className="account-area pt-10 pb-10 backgroundimage">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-8 col-md-10">
              <div className="account-wrap">
                <div className="account-main">
                  <div className="sing_image">
                    <Link to="/">
                      <img src="assets/img/logo/f1visalogo8.svg" alt />
                    </Link>
                  </div>
                  <h3 className="account-title">Set Password</h3>
                  <span className="account-form">
                    <div className="account-form-item mb-15" ref={ref}>
                      <Dropdown
                        menu={{
                          items,
                        }}
                        open={open}
                      >
                        <TextField
                          style={{ width: "100%" }}
                          type={showPassword ? "text" : "password"}
                          label="Enter Password"
                          id="outlined-start-adornment"
                          error={passwordError !== "" ? true : false}
                          helperText={passwordError}
                          onChange={(e) => {
                            changePassword(e);
                          }}
                          value={password}
                          autocomplete="off"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                onClick={handleClickShowPassword}
                                className="currsor_po"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Dropdown>
                    </div>
                    <div className="account-form-item mb-15">
                      <TextField
                        style={{ width: "100%" }}
                        type={showCnfPassword ? "text" : "password"}
                        label="Confirm Password"
                        id="outlined-start-adornment"
                        error={cnfPasswordError !== "" ? true : false}
                        helperText={cnfPasswordError}
                        onChange={(e) => {
                          changeConfermPassword(e);
                        }}
                        value={cnfPassword}
                        autocomplete="off"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              onClick={handleClickShowCnfPassword}
                              className="currsor_po"
                            >
                              {showCnfPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>

                    <div className="account-form-button">
                      {buttonloading === true ? (
                        <>
                          <button
                            type="submit"
                            disabled={true}
                            className="account-btn"
                          >
                            Loading...
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="account-btn"
                            onClick={(e) => passwordClick(e)}
                          >
                            Set Password
                          </button>
                        </>
                      )}
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Password;
